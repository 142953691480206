import React from "react";

export const Header = (props) => {
  return (
    <header id="header">
      <div className="containerv">
        <video id="video" loop muted playsInline autoPlay>
          <source src="../video/electricity_grid-1.mp4" type="video/mp4" />
            Your browser does not support the video tag.
        </video>
        <div className="overlayv">
          
            
              <div className="col-md-8 col-md-offset-2 intro-text">
                <h1 class="introtitle">
                  {props.data ? props.data.title : "Loading"}
                  <span></span>
                </h1>
                <p class="intropara">{props.data ? props.data.paragraph : "Loading"}</p>
                <a
                  href="#features"
                  className="btn btn-custom btn-lg page-scroll"
                >
                  Learn More
                </a>{" "}
              </div>
            
          
        </div>
      </div>
    </header>
  );
};
